import React, { useState } from 'react';
import { Chevron } from '../Header/Icons';

const Dropdown = ({ selectedOption, children }) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	return (
		<>
			<button
				type="button"
				className="w-full px-3 py-2 text-left border-2 dropdown-btn border-brand-300"
				onClick={() => setDropdownOpen(!dropdownOpen)}
			>
				<span>{selectedOption}</span>
				<Chevron className="inline float-right h-6 mt-auto" />
			</button>
			{dropdownOpen && (
				<button
					type="button"
					onClick={() => setDropdownOpen(false)}
					className="w-full bg-white shadow-lg dropdown-content lg:shadow-none"
				>
					{children}
				</button>
			)}
		</>
	);
};

export default Dropdown;
