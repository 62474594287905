/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
import React, { useRef } from 'react';
import { useLocation } from '@reach/router';
import { graphql } from 'gatsby';
import Slider from 'react-slick';
import { nanoid } from 'nanoid';
import Container from '../components/Container';
import DefaultLayout from '../layouts/Default';
import ContactCard from '../components/CMSBlocks/ContactCard';
import ImageWithCopy from '../components/CMSBlocks/ImageWithCopy';
import MetricsPanel from '../components/CMSBlocks/MetricsPanel';
import VideoWithCopy from '../components/CMSBlocks/VideoWithCopy';
import Hero from '../components/CMSBlocks/Hero';
import SEO from '../components/SEO';
import TabButton from '../components/TabButton';
import NewsCard from '../components/NewsCard';
import Dropdown from '../components/Dropdown';
import Phone from '../icons/phone.svg';
import Mail from '../icons/mail.svg';
import PhoneWhite from '../icons/phone2.svg';
import MailWhite from '../icons/mail2.svg';
import MiriamPress from '../images/Miriam-new-press-office.png';
import useFuse from '../hooks/useFuse';
import CrossThin from '../icons/Cross-thin.svg';
import SearchIcon from '../icons/Search.svg';
import Pagination from '../components/Pagination';
import usePagination from '../hooks/usePagination';
import usePaginatedSearch from '../hooks/usePaginatedSearch';

const sortArticles = (articles) =>
	articles.sort((a, b) => new Date(b.releaseDate) - new Date(a.releaseDate));

const NewsPage = ({ data }) => {
	const {
		newsCarousel,
		contactCards,
		cardsTitle,
		newsElements,
		hero,
		cardsPrimaryContactNumber,
		cardsPrimaryContactEmail,
	} = data.datoCmsNews;
	const { nodes: pressReleases } = data.allMultisitePressReleases;
	const { nodes: inTheNews } = data.allMultisiteInTheNews;
	const { nodes: awards } = data?.allMultisiteAwards;
	const allLatestNews = sortArticles([...inTheNews, ...pressReleases]);
	const sortedPressReleases = sortArticles(pressReleases);
	const sortedInTheNews = sortArticles(inTheNews);
	const sortedAwards = sortArticles(awards);
	const { page } = usePagination();
	const searchInputRef = useRef();
	const settings = {
		mobileFirst: true,
		dots: false,
		infinite: true,
		speed: 1000,
		variableWidth: false,
		autoplay: true,
		slidesToScroll: 1,
		slidesToShow: 5,
		responsive: [
			{
				breakpoint: 440,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 1150,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 1300,
				settings: {
					slidesToShow: 5,
					slidesToScroll: 1,
				},
			},
		],
	};

	const location = useLocation();
	const tabMap = {
		all: { text: 'All', data: allLatestNews },
		press: { text: 'Press Releases', data: sortedPressReleases },
		news: { text: 'In the News', data: sortedInTheNews },
		awards: { text: 'Awards', data: sortedAwards },
	};

	const { filter } = usePaginatedSearch({
		search: () => {},
		searchInputRef: null,
	});

	const TabContent = ({ results }) =>
		results.length > 0 ? (
			<div className="block grid-cols-3 gap-3 lg:grid">
				{results.map((newsItem) => (
					<NewsCard key={nanoid()} newsItem={newsItem} />
				))}
			</div>
		) : (
			<div className="pt-3 text-center">
				No results found, please try a different search term or clear your filters
			</div>
		);

	const fuseOptions = {
		keys: [
			{
				name: 'title',
				weight: 0.7,
			},
			{
				name: 'articleCopy',
				weight: 0.3,
			},
		],
		minMatchCharLength: 3,
		isCaseSensitive: false,
		distance: 1000,
		threshold: 0.3,
	};

	const { result, search } = useFuse(
		filter === 'all'
			? allLatestNews
			: filter === 'press'
			? pressReleases
			: filter === 'news'
			? inTheNews
			: filter === 'awards'
			? awards
			: allLatestNews,
		fuseOptions
	);

	const { searchValue, clearSearch, updateSearchResults } = usePaginatedSearch({
		searchInputRef,
		search,
	});

	const PER_PAGE = 9;

	const pageUpdate = page;

	const indexOfLastDocuments = pageUpdate * PER_PAGE;
	const indexOfFirstDocuments = indexOfLastDocuments - PER_PAGE;

	const pageNumbers = [];
	for (let i = 1; i <= Math.ceil(result.length / PER_PAGE); i++) {
		pageNumbers.push(i);
	}

	return (
		<div>
			<SEO
				path={location.pathname}
				title="Peninsula in the news"
				description="As the biggest provider of employment support, the Peninsula Group is regularly featured in major news sites around the globe."
			/>
			{hero.map((el) => (
				<div key={nanoid()}>
					<Hero
						bgImg={el.backgroundImage.url}
						title={el.heroTitle}
						content={el.heroTextNode.childMdx.body}
					/>
				</div>
			))}

			<section>
				<Container className="my-12 lg:px-3">
					<h3 className="m-auto mb-4 text-3xl text-center lg:mb-8">Latest news</h3>
					<p>Search by keyword:</p>
					<div className="relative mt-4 mb-5">
						<input
							id="search"
							placeholder="Search by keyword..."
							className="w-full px-12 py-2 mx-auto placeholder-gray-500 border outline-none border-secondary-400"
							onChange={(e) => {
								updateSearchResults(e);
							}}
							ref={searchInputRef}
							value={searchValue}
						/>
						<CrossThin
							className="absolute top-[14px] right-4 hover:cursor-pointer"
							onClick={() => clearSearch()}
						/>
						<SearchIcon className="absolute top-3 left-4" />
					</div>

					<p>Filter by Type:</p>
					<div className="hidden py-4 lg:flex lg:gap-3">
						{Object.keys(tabMap).map(
							(tabId) =>
								tabMap[tabId].data?.length > 0 && (
									<TabButton
										isSelected={filter === tabId}
										href={`?page=1${`&filter=${tabId}`}${
											searchValue ? `&search=${searchValue}` : ''
										}`}
										key={tabId}
									>
										{tabMap[tabId].text}
									</TabButton>
								)
						)}
					</div>

					<div className="w-full py-5 pointer-events-auto select-none dropdown lg:hidden">
						<Dropdown selectedOption={tabMap[filter]?.text || tabMap.all.text}>
							{Object.keys(tabMap).map(
								(tabId) =>
									tabMap[tabId].data?.length > 0 && (
										<TabButton
											isSelected={filter === tabId}
											href={`?page=1${`&filter=${tabId}`}${
												searchValue ? `&search=${searchValue}` : ''
											}`}
											key={tabId}
										>
											{tabMap[tabId].text}
										</TabButton>
									)
							)}
						</Dropdown>
					</div>
					<TabContent
						results={result.slice(indexOfFirstDocuments, indexOfLastDocuments)}
					/>
					{result.length > PER_PAGE && (
						<div className="mt-8">
							<Pagination page={page} pageNumbers={pageNumbers} filter={filter} />
						</div>
					)}
				</Container>
			</section>

			<section className="py-10 bg-secondary-200">
				<h3 className="m-auto mb-0 text-2xl font-bold text-center text-white md:mb-8">
					Recently seen in...
				</h3>
				<div>
					<div className="relative px-0 py-4 overflow-hidden text-center">
						<div className="mt-2 mb-10 hover:cursor-pointer">
							<Slider {...settings}>
								{newsCarousel.map((badge) => (
									<div
										className="item text-center !flex justify-center"
										key={nanoid()}
									>
										<img
											className="w-40 md:w-48 sm:w-32"
											src={badge.url}
											alt={badge.alt}
										/>
									</div>
								))}
							</Slider>
						</div>
					</div>
				</div>
			</section>

			{newsElements.map((el) => (
				<div key={nanoid()}>
					{el.blocktype === 'ImageWithCopy' && (
						<ImageWithCopy
							imgSrc={el.imageWithCopyImage.url}
							alt={el.imageWithCopyImage.alt}
							text={el.textNode.childMdx.body}
							imagePosition={el.imagePosition}
							bgColor={el.backgroundColour}
							className="!mb-0"
						/>
					)}
					{el.blocktype === 'VideoWithCopy' && (
						<VideoWithCopy
							text={el.textNode.childMdx.body}
							videoPosition={el.videoPosition}
							youtubeId={el.youtubeId}
							vimeoId={el.vimeoId}
							bgColor={el.backgroundColour}
							videoSubText={el.videoSubText}
						/>
					)}
					{el.blocktype === 'MetricsPanel' && (
						<MetricsPanel
							bgdImg={el.backgroundImage.url}
							title={el.title}
							text={el.textNode.childMdx.body}
						/>
					)}
				</div>
			))}

			<section className="relative flex flex-col bg-no-repeat bg-cover bg-contact-card-gradient-smScreen bg-secondary-100 md:block">
				<div className="bg-contact-card-gradient-lgScreen relative before:md:absolute before:md:h-full before:md:w-full bg-center  before:md:top-2.5 before:md:left-0 after:md:content-[''] md:bg-black">
					<Container className="pb-0 md:px-3 lg:px-6 md:pb-48">
						<div className="relative flex flex-col-reverse items-center border-white border-opacity-30 md:border-b xl:items-end xl:flex-row ">
							<div className="xl:w-1/3">
								<img
									src={MiriamPress}
									className="scale-x-[-1] md:scale-x-100"
									alt="Miriam Payne - Peninsula Press Office leader"
								/>
							</div>
							<div className="w-full my-6 text-white xl:w-2/3">
								<h3 className="mb-3 text-3xl text-center text-black md:text-left md:text-white text-bold">
									{cardsTitle}
								</h3>
								<p className="mb-2 text-center md:hidden">
									We are happy to help with any media enquiries you might have.{' '}
									<span className="font-bold text-black">
										If you are member of the media
									</span>{' '}
									contact Miriam Payne using the details below and you can route
									your request to one of our dedicated press offices for each
									country.
								</p>
								<div className="hidden md:block">
									<h4 className="mb-3 text-xl font-normal text-white">
										We are happy to help with any media enquiries you might
										have. If you are a member of the media, please contact one
										of our dedicated press offices using the details below.
									</h4>
									<p className="mb-3 text-white">
										Our press offices are led by Miriam Payne, Director of Group
										PR and Communications UKI.
									</p>
									<p className="mb-3 text-white">
										Miriam is an Emmy award winning journalist with over 15
										years’ experience in television news, including five years
										as Foreign News Desk Editor for NBC News. She is also a
										published children’s book author. After leaving journalism,
										Miriam moved into PR, working in both the private and third
										sectors before joining the Peninsula Group in 2021.
									</p>
									<p className="mb-3 text-white">
										Miriam plays a key role in positioning Peninsula Group as
										the leading authority on all things HR, employment law,
										health & safety, and employee wellbeing, as well as staying
										abreast of all industry news and developments.
									</p>
									<p className="mb-3 text-white">
										The teams work closely with contacts within trade, national
										and international press to provide timely, relevant, and
										informative content, securing high impact TV and radio
										interviews for expert spokespeople from the Peninsula Group.
									</p>
								</div>
								<div className="py-2 mt-auto">
									<div className="flex items-center mb-2">
										<Phone className="md:hidden" width="30" alt="phone icon" />
										<PhoneWhite
											className="hidden text-black md:block"
											width="30"
											alt="phone icon"
										/>
										<a
											className="hidden ml-3 font-bold text-brand-200 md:text-white sm:inline-block"
											href={`tel:${cardsPrimaryContactNumber}`}
										>
											{cardsPrimaryContactNumber}
										</a>
										<a
											className="inline-block ml-3 font-bold text-brand-200 md:text-white sm:hidden"
											href={`tel:${cardsPrimaryContactNumber}`}
										>
											Contact Miriam
										</a>
									</div>
									<div className="flex items-center">
										<Mail width="30" alt="email icon" className="md:hidden" />
										<MailWhite
											width="30"
											alt="email icon"
											className="hidden text-black md:block"
										/>
										<a
											className="hidden ml-3 font-bold text-brand-200 md:text-white sm:inline-block"
											href={`mailto:${cardsPrimaryContactEmail}?subject=Request comment from Miriam Payne`}
										>
											{cardsPrimaryContactEmail}
										</a>
										<a
											className="inline-block ml-3 font-bold text-brand-200 md:text-white sm:hidden"
											href={`mailto:${cardsPrimaryContactEmail}?subject=Request comment from Miriam Payne`}
										>
											Email Miriam
										</a>
									</div>
								</div>
							</div>
						</div>
					</Container>
				</div>

				<Container>
					<div className="grid w-full grid-cols-1 gap-6 md:-mt-48 lg:grid-cols-2 auto-rows-fr">
						{contactCards.map((el) => (
							<div key={nanoid()} className="auto-rows-fr">
								<ContactCard
									bgdImg={el.backgroundImage.url}
									header={el.header}
									address={el.addressNode.childMdx.body}
									phone={el.phoneNumber}
									email={el.email}
								/>
							</div>
						))}
					</div>
					<div className="w-full m-auto my-8 text-center md:w-3/4">
						<p className="mb-2">
							To contact our global media relations team, please email{' '}
							<a
								className="font-bold underline text-brand-200 hover:text-brand-100"
								href="mailto:uk.pressoffice@peninsulagroupglobal.com"
							>
								uk.pressoffice@peninsulagroupglobal.com
							</a>
						</p>
						<p>
							Please use this email for media requests only. Due to the volume of
							enquiries, please help us by specifying your deadline.
						</p>
					</div>
				</Container>
			</section>
		</div>
	);
};

const WrappedNewsPage = (props) => (
	<DefaultLayout fullWidth>
		<NewsPage {...props} />
	</DefaultLayout>
);

export default WrappedNewsPage;

export const query = graphql`
	query newspage {
		datoCmsNews {
			newsElements {
				... on DatoCmsImageWithCopy {
					blocktype
					imagePosition
					backgroundColour
					textNode {
						childMdx {
							body
						}
					}
					imageWithCopyImage {
						url
						alt
						gatsbyImageData
					}
				}
				... on DatoCmsVideoWithCopy {
					blocktype
					videoPosition
					youtubeId
					vimeoId
					videoSubText
					backgroundColour
					textNode {
						childMdx {
							body
						}
					}
				}
				... on DatoCmsMetricsPanel {
					blocktype
					title
					textNode {
						childMdx {
							body
						}
					}
					backgroundImage {
						url
					}
				}
			}
			hero {
				... on DatoCmsHero {
					heroTextNode {
						childMdx {
							body
						}
					}
					backgroundImage {
						url
					}
					heroTitle
				}
			}
			cardsTitle
			cardsPrimaryContactNumber
			cardsPrimaryContactEmail
			contactCards {
				... on DatoCmsContactCard {
					addressNode {
						childMdx {
							body
						}
					}
					blocktype
					email
					header
					phoneNumber
					backgroundImage {
						url
					}
				}
			}
			newsCarousel {
				url
				alt
				gatsbyImageData(layout: CONSTRAINED, imgixParams: { fm: "png", auto: "compress" })
			}
		}
		allMultisitePressReleases {
			nodes {
				id
				title
				subtitle
				externalArticleLink
				releaseDate
				slug
				isArticleInternal
				newsType
				articleImage {
					url
				}
				articleCopy
			}
		}
		allMultisiteInTheNews {
			nodes {
				title
				subtitle
				externalArticleLink
				releaseDate
				id
				slug
				isArticleInternal
				newsType
				articleImage {
					url
				}
				articleCopy
			}
		}
		allMultisiteAwards(filter: { id: { ne: "5b1b086e-dc25-59bb-975d-14743ad0bc14" } }) {
			nodes {
				title
				subtitle
				externalArticleLink
				releaseDate
				id
				slug
				isArticleInternal
				newsType
				articleCopy
				itemCountry
			}
		}
	}
`;
