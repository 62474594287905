import React from 'react';
import cn from 'classnames';
import { isFirstPage, isLastPage } from './utils';

const Pagination = ({ pageNumbers, page, className, searchInput, filter }) => {
	const searchParam = searchInput ? `&search=${searchInput}` : '';
	const filterParam = filter ? `&filter=${filter}` : '';

	return (
		<div
			className={cn(`w-full mb-6 text-center`, className, {
				pagination: pageNumbers.length > 5,
			})}
		>
			{!isFirstPage(page, pageNumbers) && (
				<a
					href={`?page=${page - 1}${filterParam}${searchParam}`}
					className="mr-2 font-bold text-brand-200 hover:text-brand-400"
				>
					Previous
				</a>
			)}

			{pageNumbers.map((number, index) => (
				<a
					key={number}
					id={number}
					className={cn(
						'px-3 py-1 mx-1 my-2 font-bold bg-gray-200 text-brand-200 page-item hover:text-brand-400',
						{
							'bg-transparent text-brand-400 pointer-events-none px-2 current-page':
								number === page,
							'next-page': number === page + 4,
							'upcoming-page': number < page + 4 && number > page,
							'previous-page': number === page - 1 && page !== 3,
							'previous-page-exception': number === page - 1 && page === 3,
							'first-page': pageNumbers[0] === index + 1,
							'last-page': pageNumbers.length - 1 === index,
						}
					)}
					href={`?page=${number}${filterParam}${searchParam}`}
				>
					{number}
				</a>
			))}
			{!isLastPage(page, pageNumbers) && (
				<a
					href={`?page=${page + 1}${filterParam}${searchParam}`}
					className="ml-2 font-bold text-brand-200 hover:text-brand-400"
				>
					Next
				</a>
			)}
		</div>
	);
};

export default Pagination;
