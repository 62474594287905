import { useEffect, useState, useCallback } from 'react';
import { sanitizeUtmString } from '../../components/Pagination/utils';

const usePaginatedSearch = ({ search, searchInputRef }) => {
	const isSearchParam = typeof window !== 'undefined' && window.location.search;
	const utmString = isSearchParam ? window.location.search : 'page=1';

	const [showSearch, setShowSearch] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const [filter, setFilter] = useState('');

	const setSearchResult = useCallback(
		(value, display) => {
			setSearchValue(value);
			search(value);
			setShowSearch(display);
		},
		[search]
	);

	const clearSearch = () => {
		setSearchResult('', false);
		window.location.search = `?page=1${filter && `&filter=${filter}`}`;
	};

	const updateSearchResults = (e) => {
		const searchTarget = e ? e.target.value : searchInputRef.current.value;

		setSearchResult(searchTarget, true);
		window.history.replaceState(
			{ search: searchTarget },
			'',
			`${`?page=1${filter && `&filter=${filter}`}&search=${searchTarget}`}`
		);

		if (searchTarget?.length === 0) {
			clearSearch();
		}
	};

	const handleKeyDown = (e) => {
		if (e.key === 'Enter' || e.which === 13 || e.keyCode === 13) {
			updateSearchResults();
		}

		if (e.key === 'Escape' || e.which === 27 || e.key === 27) {
			clearSearch();
		}
	};

	useEffect(() => {
		if (utmString.includes('search')) {
			setSearchResult(sanitizeUtmString(utmString, 'search'), true);
		}
		if (utmString.includes('filter')) {
			setFilter(sanitizeUtmString(utmString, 'filter'), false);
		}
	}, [utmString, setSearchResult]);

	return { showSearch, searchValue, updateSearchResults, clearSearch, handleKeyDown, filter };
};

export default usePaginatedSearch;
