import React from 'react';
import cn from 'classnames';
import { Link } from '@reach/router';
import Container from '../../Container';
import CopyBlock from '../../CopyBlock';
import ChevronRight from '../../CopyBlock/components/ChevronRight';
import MultiParagraphBlock from '../../MultiParagraphBlock';

const Hero = ({
	bgImg,
	content,
	description,
	title,
	breadcrumbs,
	articleSubHeading,
	fullWidth,
	tabs,
}) => (
	<section
		className="z-0 bg-no-repeat bg-cover hero bg-none lg:z-10 min-h-[250px]"
		style={{ backgroundImage: `url(${bgImg})` }}
	>
		<div
			className={cn(`relative  h-full pt-8 bg-gradient-dark bg-secondary-100 min-h-[250px]`, {
				'pb-8': !tabs,
			})}
		>
			<Container className={cn(`flex flex-col`, { 'lg:flex-row': !fullWidth })}>
				<div className={cn(`w-full`, { 'lg:w-1/2': !fullWidth })}>
					<p className="mb-8 overflow-hidden text-sm sm:text-white text-ellipsis whitespace-nowrap">
						<Link
							to="/"
							className="pr-2 text-sm underline text-brand-400 lg:text-white hover:no-underline"
						>
							Home
						</Link>
						<span className="inline-block">
							<ChevronRight />
						</span>
						{breadcrumbs?.parent && (
							<>
								<a
									href={breadcrumbs.parent.url}
									className="hidden px-2 text-sm underline md:inline-block hover:no-underline"
								>
									{breadcrumbs.parent.title}
								</a>
								<span className="hidden md:inline-block">
									<ChevronRight />
								</span>
							</>
						)}
						<span className="pl-2 text-sm font-bold text-brand-400 lg:text-white">
							{breadcrumbs?.currentPage || title}
						</span>
					</p>
					<h1 className="mb-2 text-4xl font-normal text-brand-400 lg:text-white lg:text-5xl">
						{title}
					</h1>
				</div>

				{articleSubHeading && (
					<div className="w-full mt-2 text-brand-400 md:!text-white md:mt-0 md:w-4/6">
						<MultiParagraphBlock content={articleSubHeading} />
					</div>
				)}

				{(description || content) && (
					<div className="w-full mt-2 text-brand-400 lg:!text-white lg:w-1/2 lg:mt-0">
						<h3 className="mb-4 text-2xl font-normal lg:text-3xl">{description}</h3>
						{content && <CopyBlock>{content}</CopyBlock>}
					</div>
				)}
				{tabs && tabs}
			</Container>
		</div>
	</section>
);

export default Hero;
