import React from 'react';
import { Link } from 'gatsby';
import { format } from 'date-fns';
import ChevronRight from '../../icons/chevron-right.svg';

const NewsCard = ({ newsItem, type, parentSlug }) => {
	const { title, releaseDate, isArticleInternal, slug, externalArticleLink, newsType, tags } =
		newsItem;

	const newsTypeMap = {
		PressRelease: 'Press Release - ',
		InTheNews: 'In the News - ',
		Awards: 'Awards - ',
	};
	const newsTypeId = Object.keys(newsTypeMap).filter((key) => newsType?.includes(key));
	const createTags = () => {
		if (tags) {
			return tags.split(',').map((tag) => tag.trim());
		}
		return [];
	};

	const createLink = () => {
		if (type === 'charity') {
			return `/${parentSlug}/${slug}`;
		}
		return `/news/${slug}`;
	};
	return (
		<div className="my-4 border border-secondary-100 lg:my-0">
			{newsItem?.articleMainImage?.url && (
				<Link to={`/news/${slug}`} target="_blank" rel="noreferrer">
					<img
						src={newsItem?.articleMainImage?.url}
						alt={newsItem?.articleMainImage?.alt}
						className="w-full h-40 object-cover object-top"
					/>
				</Link>
			)}
			<div className="p-4">
				<p className="pb-2 text-xs text-gray-500 inline-flex  space-x-1">
					{type && <span className="uppercase text-xs">Press Release - </span>}
					<span className="pb-2 text-xs uppercase">
						{`${newsTypeMap[newsTypeId] || ''}${format(
							new Date(releaseDate),
							'dd/MM/yyyy'
						)}`}
					</span>
				</p>
				<h3 className="text-xl mb-4 !font-normal h-[62px] overflow-hidden">{title}</h3>
				{isArticleInternal ? (
					<Link
						to={createLink()}
						target="_blank"
						rel="noreferrer"
						className="flex items-center font-bold text-brand-200"
					>
						<ChevronRight className="inline-block mx-2" /> Read more
					</Link>
				) : (
					<a
						href={externalArticleLink}
						target="_blank"
						rel="noreferrer"
						className="flex items-center font-bold text-brand-200"
					>
						<ChevronRight className="inline-block mx-2" /> Read more
					</a>
				)}
				{tags && (
					<span className="flex flex-wrap mt-4">
						{createTags().map((tag) => (
							<span
								key={tag}
								className="text-xs bg-secondary-100 text-secondary-500 px-2 py-1 mr-2 mb-2"
							>
								# {tag}
							</span>
						))}
					</span>
				)}
			</div>
		</div>
	);
};

export default NewsCard;
