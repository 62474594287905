export const isLastPage = (currentPage, pages) => pages[pages.length - 1] === currentPage;
export const isFirstPage = (currentPage, pages) => pages[0] === currentPage;

export const sanitizeUtmString = (utm, param, paramType = 'string') => {
	const splitParam = utm.split(`${param}=`);
	const postValue = splitParam[1];
	let val = decodeURI(postValue);

	if (val.includes('/')) {
		val = val.replace('/', '');
	}
	if (val.includes('&')) {
		const splitAndParam = val.split('&');
		const preValue = splitAndParam[0];
		val = decodeURI(preValue) || val;
	}

	if (paramType === 'number') {
		return parseInt(postValue, 10) || 1;
	}

	return val;
};

export const validatePageNumber = (pageNumbers, page) =>
	pageNumbers.find((number) => number === page) || pageNumbers[pageNumbers.length - 1];
