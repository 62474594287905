import React from 'react';

const TabButton = ({ children, isSelected, href }) => (
	<a
		href={href}
		className={`inline-block w-full p-3 font-bold text-center cursor-pointer lg:mx-0 whitespace-nowrap ${
			isSelected
				? 'bg-brand-100 text-white lg:border-none'
				: 'hover:bg-brand-100 text-brand-400'
		}  focus:text-white hover:text-white focus:bg-brand-100 border-x border-b lg:border-t border-secondary-400`}
	>
		{children}
	</a>
);

export default TabButton;
