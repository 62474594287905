import { useEffect, useState } from 'react';
import { sanitizeUtmString } from '../../components/Pagination/utils';

const usePagination = (totalNumOfResults, perPage) => {
	const isSearchParam = typeof window !== 'undefined' && window.location.search;
	const utmString = isSearchParam ? window.location.search : 'page=1';
	const [page, setPage] = useState(1);

	useEffect(() => {
		if (utmString.includes('page')) {
			setPage(sanitizeUtmString(utmString, 'page', 'number'));
		}
	}, [utmString]);

	const indexOfLastResult = page * perPage;
	const indexOfFirstResult = indexOfLastResult - perPage;

	const currentPageResults = (allResults) =>
		allResults.slice(indexOfFirstResult, indexOfLastResult);

	const pageNumbers = [];

	for (let i = 1; i <= Math.ceil(totalNumOfResults / perPage); i++) {
		pageNumbers.push(i);
	}

	return { page, pageNumbers, currentPageResults };
};

export default usePagination;
