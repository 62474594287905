import { useState, useMemo } from 'react';
import Fuse from 'fuse.js';

function fuzzySearch({ fuse, data, term }) {
	return term ? fuse.search(term).map((s) => s.item) : data;
}

/**
 *
 * @param {*} param0
 *
 * A custom React Hook to do a in-memory fuzzy text search
 * using Fuse.js.
 */
function useFuse(data, options) {
	const [term, setTerm] = useState('');

	const fuse = useMemo(
		() =>
			new Fuse(data, {
				...options,
			}),
		[data, options]
	);

	const result = fuzzySearch({ data, term, fuse });

	const reset = () => setTerm('');

	return { result, search: setTerm, term, reset };
}

export default useFuse;
